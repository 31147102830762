import { reactive,ref } from 'vue'
import Api_ from '@/api/index'
import { ElNotification,ElMessageBox } from 'element-plus'
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export default function (e,obj_) { 
    const page = reactive({
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        list: [],
    })
    const requestUrl = ref(e)
    const otherParm = ref(obj_)
    const listLoading = ref(false)
    const dialogFormVisible = ref(false)
    const parmSearch = ref({})

    const sizeChange = (pageSize)=>{ 
        page.pageSize = pageSize;
        getList(1)
    }
    const currentChange = (currentPage)=>{ 
        page.currentPage = currentPage;
        getList(1)
    }
    const updateOtherParm = (e)=>{//修改外部参数
        otherParm.value = e
    }
    const changeParm = (e,isRemove)=>{//获得筛选框的改变
        parmSearch.value = e
        if(isRemove){
            page.currentPage = 1
        }
    }
    const getList = (e)=>{
        let obj_ = {
            current: page.currentPage,
            size: page.pageSize,
            ...otherParm.value,
            ...parmSearch.value,
        }
        if(e && e !== 1){
            obj_ = Object.assign({}, obj_, e)
        }
        for(let i in obj_){
            if(obj_[i] === ''){
                delete obj_[i]
            }
        }
        listLoading.value = true
        Api_[requestUrl.value[0]](obj_).then(res=>{
            listLoading.value = false
            if(res.code === 0){
                page.list = res.data.records
                page.total = res.data.total
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
    }
    const rowDel = (row)=>{
        ElMessageBox.confirm( t("alert.selEnter"), t("tyle_.tits"), {
            confirmButtonText: t("button.enter"),
            cancelButtonText: t("button.close"),
            type: 'warning',
        }).then(()=>{
            Api_[requestUrl.value[1][0]](row[requestUrl.value[1][1]]).then(res=>{
                if(res.code ===0 ){
                    getList(1)
                    ElNotification({
                        message: t("alert.succDel"),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        })
    }

    const successFun = ()=>{
        getList(1)
        dialogFormVisible.value = false
    }
    // 暴露出去
    return {
        page,listLoading,
        sizeChange,
        currentChange,
        getList,rowDel,
        dialogFormVisible,
        successFun,updateOtherParm,
        changeParm
    }
}
